<template>
  <div style="margin-top:40px;">
    <h4>Rent Manangement</h4>
    <el-divider></el-divider>
    <el-row style="padding:10px;">
      <el-col :span="6" style="color:#fff;">1</el-col>
      <el-col :span="6">
        <el-card class="card-btn" :body-style="{padding:'0px'}" @click.native="gotoRent('suntec')">
          <el-image  class="card-image"  fit="cover" :src="require('../../assets/suntec-cover.jpg')"></el-image>
          <div class="card-text">
            <strong>Suntec</strong>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="card-btn" :body-style="{padding:'0px'}" @click.native="gotoRent('onenorth')">
          <el-image  class="card-image"  fit="cover" :src="require('../../assets/onenorth-cover.jpg')"></el-image>
          <div class="card-text">
            <strong>One North</strong>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" style="color:#fff;">1</el-col>
    </el-row>
    <el-row style="padding:10px;">
      <el-col :span="6" style="color:#fff;">1</el-col>
      <!--<el-col :span="6">
        <el-card class="card-btn" :body-style="{padding:'0px'}" @click.native="gotoRent('oue')">
          <el-image  class="card-image"  fit="cover" :src="require('../../assets/oue-cover.jpg')"></el-image>
          <div class="card-text">
            <strong>OUE</strong>
          </div>
        </el-card>
      </el-col>-->
      <el-col :span="6">
        <el-card class="card-btn" :body-style="{padding:'0px'}" @click.native="gotoRent('bugis')">
          <el-image class="card-image"  fit="cover" :src="require('../../assets/bugis-cover.jpg')"></el-image>
          <div class="card-text">
            <strong>Bugis</strong>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" style="color:#fff;">1</el-col>
    </el-row>
  </div>
</template>

<script>
export default {
    name: 'maintenance',
    methods: {
      gotoRent(val) {
        window.location.href = '/pages/rent-view/' + val
      }
    }
}
</script>

<style scoped>
.card-btn {
  margin: 10px;
}
.card-image {
  height: 190px;
  width: 100%;
}
.card-text {
  height: 40px;
  line-height: 40px;
}
</style>